import { pushService } from '@/services/push-service.ts';
import { traceError } from '@/utils/trace-error';
import { setUser } from '@sentry/remix';
import { AuthTokens, fetchAuthSession, JWT, signOut } from 'aws-amplify/auth';
import mixpanel from 'mixpanel-browser';

type RealJwtPayload = {
  aud: string;
  auth_time: number;
  ['cognito:groups']: string[];
  ['cognito:preferred_role']: string;
  ['cognito:roles']: string[];
  ['cognito:username']: string;
  email: string;
  email_verified: boolean;
  event_id: string;
  exp: number;
  iat: number;
  is_admin: string;
  iss: string;
  jti: string;
  origin_jti: string;
  preferred_username?: string;
  sub: string;
  token_use: string;
  name?: string;
};

export type Tokens = Omit<AuthTokens, 'idToken'> & {
  idToken: Omit<JWT, 'payload'> & { payload: RealJwtPayload };
};

type SessionData = {
  tokens?: Tokens;
};

let sessionDataPromise: Promise<SessionData> | undefined;

let forceRefresh = false;

export const invalidateSession = () => {
  sessionDataPromise = undefined;

  // force refresh needed to update preferred username after user selects one during signup
  forceRefresh = true;
};

const fetchSession = async () => {
  const tokens = (await fetchAuthSession({ forceRefresh })).tokens as
    | Tokens
    | undefined;

  forceRefresh = false;

  if (tokens?.idToken?.payload.sub) {
    mixpanel.identify(tokens.idToken.payload.sub);
    mixpanel.people.set({
      $name: tokens.idToken.payload.preferred_username,
      $email: tokens.idToken.payload.email,
    });

    setUser({
      id: tokens.idToken.payload.sub,
      email: tokens.idToken.payload.email,
      username: tokens.idToken.payload.preferred_username,
    });
  }

  return { tokens };
};

export const getAuthorizedData = () =>
  (sessionDataPromise ??= fetchSession())
    .catch(async (err) => {
      if (err.name === 'NetworkError') {
        throw err;
      }

      traceError(new Error('Failed to fetch session data', { cause: err }));

      try {
        const subscription = await pushService.getSubscription();
        if (subscription) await subscription.unsubscribe();
      } catch (err) {
        traceError(
          new Error(
            'Failed to unsubscribe from push notifications on token expire',
            { cause: err },
          ),
        );
      }

      await signOut();

      return {
        tokens: undefined,
      } satisfies SessionData;
    })
    .finally(() => {
      sessionDataPromise = undefined;
    });
